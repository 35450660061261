<template>
    <div>
      <div id="recaptcha-container"></div>
      <p v-if="recaptchaError">{{ recaptchaError }}</p>
    </div>
  </template>
  
<script>

  import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

  export default {
    props: {
        setCaptchaStatus: Function
    },
    data() {
      return {
        recaptchaError: '',
      };
    },
    mounted() {
        const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            size: 'normal',
            'expired-callback': this.onRecaptchaExpired,
            'error-callback': this.onRecaptchaError,
            callback: this.onRecaptchaSuccess
        });

        // this.setCaptchaStatus(recaptchaVerifier)
        recaptchaVerifier.render().then((widgetId) => {
          recaptchaVerifier.widgetId = widgetId;
        }); 
    },
    methods: {
        onRecaptchaExpired() {
            this.recaptchaError = 'reCAPTCHA verification expired. Please try again.';
            this.setCaptchaStatus(false)
        },
        onRecaptchaError() {
            this.recaptchaError = 'Error occurred during reCAPTCHA verification. Please try again.';
            this.setCaptchaStatus(false)
        },
        onRecaptchaSuccess(response) {
          // console.log('----response', response);
          this.setCaptchaStatus(true)
          
        }
    },
  };
  </script>
  