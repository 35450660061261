<template>
    <div style="height: 400px">
        <base-table :data="conversions" thead-classes="text-primary">
            <template slot-scope="{ row }">
                <!-- <td>
          <base-checkbox v-model="row.done"> </base-checkbox>
        </td> -->
                <td>
                    <p class="title">
                        New conversion of <b style="color: green">{{ row.payout }}$</b>
                    </p>
                    <p class="text-muted">
                        Offer <b style="color: green">{{ row.offer?.offer_id }}</b> is completed at:
                        <b style="color: green">{{ formatDate(row) }}</b>
                    </p>
                </td>
                <!-- <td class="td-actions text-right">
          <base-button type="link" aria-label="edit button">
            <i class="tim-icons icon-pencil"></i>
          </base-button>
        </td> -->
            </template>
        </base-table>
        <div
            v-if="conversions.length === 0"
            style="height: 100%; flex-direction: row; display: flex; justify-content: center"
        >
            <h4>No Feed Yet!</h4>
        </div>
    </div>
</template>
<script>
import { BaseTable } from "@/components";
import moment from "moment";
export default {
    components: {
        BaseTable,
    },
    props: {
        conversions: Array,
    },
    computed: {
        tableData() {
            return this.$t("dashboard.taskList");
        },
    },
    methods: {
        formatDate(row) {
            return moment(row.createdAt).format("YYYY-MM-DD hh:mm:ss");
        },
    },
};
</script>
<style></style>
