<template>
  <div
    style="
      height: 393px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
    "
  >
    <div>

      <i class="fas fa-question-circle" style="font-size: 50px; margin-bottom: 10px"></i>

      <h4 class="text-muted">Got questions? Reach out to us on:</h4>
      <div class="text-muted">
        <!-- <div>
          <base-button
              @click="openTelegram(i)"
              :backgroundColor="'#0088CC'"
              style="width: 180px;"
              class="mr-2 telegram-button"
              :type="'primary'"
              fill
          >
              <span style="margin-left: 0px !important" ><i style="font-size: 17px" class="fab fa-telegram mr-2"></i>Telegram</span>
          </base-button>
        </div> -->
        <div>
          <base-button
              @click="openDiscord()"
              class="mr-2 mt-3 discord-button"
              style="width: 180px;"
              :backgroundColor="'#5865F2'"
              :type="'primary'"
              fill
          >
              <span style="margin-left: 0px !important" ><i style="font-size: 16px" class="fab fa-discord mr-2"></i>Discord</span>
          </base-button>
        </div>
        <div>
          <base-button
              @click="openEmail()"
              class="mr-2 mt-3 discord-button"
              style="width: 180px;"
              :backgroundColor="'#525F7F'"
              :type="'primary'"
              fill
          >
              <span style="margin-left: 0px !important" ><i style="font-size: 16px" class="fas fa-envelope mr-2"></i>Email</span>
          </base-button>
        </div>

        </div>

    </div>
  </div>
</template>
<script>
import { BaseTable, BaseButton } from "@/components";
import { mapGetters } from "vuex";

export default {
  components: {
    BaseTable,
    BaseButton
  },
  computed: {
    table() {
      return this.$t("dashboard.usersTable");
    },
    ...mapGetters(["settings"]),
  },
  methods: {
    // openTelegram() {
    //   window.open('https://t.me/channel-name', "_blank")
    // },
    openDiscord() {
      window.open('https://discord.gg/x66UaQCmVJ', "_blank")
    },
    openEmail() {
      window.open('mailto:contact@adviral.io', "_blank")
    },
  }
};
</script>
<style>
  .telegram-button:hover {
    background: #0088CC !important;
  }
  .discord-button:hover {
    background: #5865F2 !important;
  }
</style>
