<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card class="login-form-card" style="margin-bottom: 20px;">

    <div class="align-items-center justify-content-center py-3 px-4 text-center column">
      <i class="fas fa-check-circle mb-4" style="font-size: 55px; color: #00da48;"></i>        <h4>
            Thank you for signing up. We will review your application and email you within 24 hours to let you know.
        </h4>
    </div>

  </card>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
// import PhoneVerification from './PhoneVerification.vue';
import BaseCheckbox from '../../components/BaseCheckbox.vue';
import BaseTextArea from '../../components/Inputs/BaseTextArea.vue';
import Captcha from '../Register/Captcha.vue'
import SocialCard from './SocialCard.vue'

export default {
  components: {
    ClipLoader,
    // PhoneVerification,
    BaseCheckbox,
    BaseTextArea,
    Captcha,
    SocialCard
  },
  data() {
    return {
      platforms: [
        {title: "Twitter", placeholder: "https://twitter.com/...", icon: "fab fa-twitter"},
        {title: "Facebook", placeholder: "https://facebook.com/...", icon: "fab fa-facebook"},
        {title: "Instagram", placeholder: "https://instagram.com/...", icon: "fab fa-instagram"},
        {title: "Twitch", placeholder: "https://twitch.com/...", icon: "fab fa-twitch"},
        {title: "Tiktok", placeholder: "https://tiktok.com/...", icon: "fab fa-tiktok"},
        {title: "Youtube", placeholder: "https://youtube.com/...", icon: "fab fa-youtube"},
        {title: "Snapchat", placeholder: "https://snapchat.com/...", icon: "fab fa-snapchat"},
        {title: "Discord", placeholder: "https://discord.com/...", icon: "fab fa-discord"},
      ],
      selectedPlatform: null
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    register: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    errorMessage: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    setRecaptchaVerifier: Function,
    goBack: Function
  },
  methods: {
    toLogin() {
      this.$store.commit("setAuthError", null);
      this.$router.replace("/login")
    },
    selectPlatform(item) {
      this.selectedPlatform = item
    }
  }
};
</script>
<style></style>
