<template>
      <div
      class="content"
    >
    <div class="row mx-0 justify-content-center " style="margin-top: 30px">
      <div class="" >
        <!-- <span style="font-size: 40px; color: #00DA48;">LOGO HERE</span> -->
        <img 
            src="/img/logo-adviral.png"
            style="width: 90px"
            class="login-logo"
        />
      </div>
      <div class="col-md-12">
        <reset-password-form 
          :step="step" :model="model" :resetPassword="resetPassword" :errorMessage="authError"
          :loading="loading"
          :verify="verify"
          :updatePassword="updatePassword"
          :successMessage="successMessage"
          :goBack="goBack"
        >
        </reset-password-form>
      </div>

      </div>
    </div>

</template>
<script>
import ResetPasswordForm from './ResetPassword/ResetPasswordForm';
import Auth from "../services/auth.js";
import { mapGetters } from "vuex"
import { validateEmail } from '../services/utils';

export default {
  components: {
    ResetPasswordForm,
  },
  data() {
    return {
      model: {
        email: null,
        code: null,
        password: null,
        confirmPassword: null
      },
      code: null,
      loading: false,
      step: 1,
      successMessage: null
    }
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return this.$t('dashboard.chartCategories');
    },
    ...mapGetters([
      'authError',
    ])
  },
  methods: {
    resetPassword: async function () {
      if (!validateEmail(this.model.email)) {
        this.$store.commit("setAuthError", "Please enter a valid email");
        return;
      };
      try {
        this.loading = true;
        this.$store.commit("setAuthError", null);
        this.$recaptchaInstance?.showBadge()
        const captchToken = await this.$recaptcha('resetPassword')
        // console.log('---recaptcha captchToken', captchToken);
        const isSuccess = await this.$store.dispatch("resetPassword", { email: this.model.email, captcha: captchToken })
        // console.log('---isSuccess,', isSuccess);
        if (isSuccess) {
          this.successMessage = "An email has been sent to your inbox with instructions to reset your password.";
          this.step = 1
          this.$recaptchaInstance?.hideBadge()
        }


      } catch (error) {
        // console.log("error", error.response)
        this.$store.commit("setAuthError", error?.response?.data?.message);
      }
      this.loading = false

    },
    verify: async function () {
      try {
        this.$store.commit("setAuthError", null);
        this.loading = true;
        const isSuccess = await this.$store.dispatch("verify", {key: this.resetKey})
        // console.log('---isSuccess,', isSuccess);
        if (isSuccess) {
          this.step = 3
        }


      } catch (error) {
        // console.log("error", error.response)
        this.$store.commit("setAuthError", error?.response?.data?.message);
      }
      this.loading = false
    },
    updatePassword: async function () {
      if (!this.resetKey || !this.model.password || this.model.password !== this.model.confirmPassword) return;
      try {
        this.$store.commit("setAuthError", null);
        this.loading = true;
        const isSuccess = await this.$store.dispatch("updatePassword", {...this.model, key: this.resetKey})
        // console.log('---isSuccess,', isSuccess);
        if (isSuccess) {
          this.step = 4
        }


      } catch (error) {
        // console.log("error", error.response)
        this.$store.commit("setAuthError", error?.response?.data?.message);
      }
      this.loading = false
    },
    goBack: function () {
      this.$store.commit("setAuthError", null);
      this.$router.push('/login')
    }
  },
  mounted() {
    this.$recaptchaInstance?.showBadge()
    this.resetKey = this.$route.query.key
    // console.log('---key', this.resetKey);
    if (this.resetKey) {
      this.loading = true
      this.step = 2
      this.verify(this.resetKey)
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  }
};
</script>
<style>

</style>
