<!-- eslint-disable vue/no-mutating-props -->
<template>
    <card style="margin-bottom: 20px;" :style="{'background-color': theme === 'black' ? '#2F3952' : '#F5F6FA'}" class="px-4 py-2 cursor-pointer" >
        <div class="column justify-content-center align-items-center " @click="selectPlatform(item)">
            <i :class="item.icon" class="mb-2" style="font-size: 25px; color: #00da48;"></i>
            <span :style="{color: theme === 'black' ? 'white' : 'black'}">{{ item.title }}</span>
        </div>

    </card>
  </template>
  <script>
  

  
  export default {
    components: {

    },
    props: {
      item: {
        type: Object,
        default: () => {
          return {};
        }
      },
      selectPlatform: Function
    },
    data() {
        return {
            theme: localStorage.getItem("theme")
        }
    }

  };
  </script>
  <style></style>
  