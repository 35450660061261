<template>
    <div class="content" style=" height: 100vh;">
        <div class="row justify-content-center mx-0" style="padding-top: 30px;">
            <div class="">
                <img 
                        src="/img/logo-adviral.png"
                        style="width: 90px"
                        class="login-logo"
                    />
            </div>
            <div class="col-md-12 signup-parent">
                <register-form v-if="currentStep === 0" :setCaptchaStatus="setCaptchaStatus" :model="model" :register="goToNextStep" :errorMessage="authError" :loading="loading">
                </register-form>
                <!-- <verification-step 
                    v-else :model="model" 
                    :errorMessage="authError" 
                    :loading="loading" 
                    :goBack="toggleShowSecondStep" 
                    :verify="verifyCode">
                </verification-step> -->
                <promotion-form 
                v-if="currentStep === 1"  :model="model" 
                    :errorMessage="authError" 
                    :loading="loading" 
                    :goBack="setCurrentStep" 
                    :register="goToNextStep">
                </promotion-form>
                <social-form 
                v-if="currentStep === 2" 
                    :model="model" 
                    :errorMessage="authError" 
                    :loading="loading" 
                    :goBack="setCurrentStep" 
                    :register="register">
                </social-form>
                <div v-if="currentStep === 3">
                    <success-message></success-message>
                </div>
            </div>
      <!-- <div id="recaptcha-container"></div> -->

        </div>
    </div>
</template>
<script>
import RegisterForm from "./Register/RegisterForm";
import Auth from "../services/auth.js";
import { mapGetters } from "vuex";
import { isValidPassword, validatePhoneNumber } from "../services/utils";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import VerificationStep from './Register/VerificationStep.vue'
import SocialForm from "./Register/SocialForm.vue";
import PromotionForm from "./Register/PromotionForm.vue";
import SuccessMessage from "./Register/SuccessMessage.vue";

export default {
    components: {
        RegisterForm,
        VerificationStep,
        SocialForm,
        PromotionForm,
        SuccessMessage
    },
    data() {
        return {
            model: {
                fullname: null,
                username: null,
                password: null,
                email: null,
                confirmPassword: null,
                referral_code: null,
                hear_about_us: null,
                accept_terms: false,
                phone_number: null,
                verificationCode: null,
                promotion_description: null,
                twitter: null,
                instagram: null,
                tiktok: null,
                facebook: null,
                twitch: null,
                youtube: null,
                snapchat: null,
                discord: null
            },
            recaptchaStatus: null,
            currentStep: 0,
            loading: false,
        };
    },
    computed: {
        enableRTL() {
            return this.$route.query.enableRTL;
        },
        isRTL() {
            return this.$rtl.isRTL;
        },
        bigLineChartCategories() {
            return this.$t("dashboard.chartCategories");
        },
        ...mapGetters(["authError"]),
    },
    methods: {
        // async sendVerificationCode() {
        //     this.$store.commit("setAuthError", null);
        //     if (!this.validateInputs()) return false;
        //     const isValidPayload = await this.validateReferrer()
        //     if (!isValidPayload) return false;
        //     this.loading = true;
        //     try {
        //         console.log('---appverifier', this.recaptchaVerifier, this.model.phone_number);
        //     this.confirmationResult = await firebase
        //         .auth()
        //         .signInWithPhoneNumber(this.model.phone_number, this.recaptchaVerifier);
        //     console.log('---this.confirmationResult');
        //     this.showSecondStep = true;
        //     } catch (error) {
        //         console.error('Error sending verification code', error);
        //         this.$store.commit("setAuthError", "Error sending the verification code, please try again.");
        //     }
        //     this.loading = false
        // },
        // async verifyCode() {
        //     console.log('---tthis.code', this.model.verificationCode);
        //     if (!this.model.verificationCode) return;
        //     this.loading = true;
        //     try {
        //         const result = await this.confirmationResult.confirm(this.model.verificationCode);
        //         console.log('User is signed in:', result.user);
        //         this.register()
        //     } catch (error) {
        //         console.error('Error verifying code', error);
        //         this.$store.commit("setAuthError", "Verification code is not correct");
        //         this.loading = false;
        //     }

        // },
        goToNextStep: async function (step) {
            this.$store.commit("setAuthError", null);
            if (this.currentStep === 0) {
                if (!this.validateInputs()) return false;
                this.loading = true
                const isValidPayload = await this.validateReferrer()
                this.loading = false;
                
                if (!isValidPayload) return false;
            }
            if (this.currentStep === 1) {
                if (!this.validatePromotionInputs()) return false;
            }

            this.setCurrentStep(step)
        },
        register: async function () {
            try {
                this.$store.commit("setAuthError", null);
                if (!this.validateSocialInputs()) return false;
                this.loading = true
                this.$recaptchaInstance.showBadge()
                const captchToken = await this.$recaptcha('register')
                // console.log('---recaptcha captchToken', captchToken);
                await Auth.register({ ...this.model, captcha: captchToken, referrer: localStorage.getItem("referrer") || this.model.referral_code });
                this.$recaptchaInstance.hideBadge()
                // console.log("---res,", response);
                // console.log("---token,", response.data.data.token);
                // Auth.setToken({ access_token: response.data.data.token });
                // this.$store.commit("isAuthenticated", { isAuthenticated: true });

                // console.log("-------this", this.$store.getters.isAuthenticated);

                this.currentStep = 3
                // setTimeout(() => {
                //     console.log("-----replace");
                //     this.$router.replace("/");
                // }, 1500);
            } catch (error) {
                console.log("error", error.response);
                this.$store.commit("setAuthError", error?.response?.data?.message);
            }
            this.loading = false;
        },
        validateInputs() {
            if (!this.model.fullname) {
                this.$store.commit("setAuthError", "Full name is required");
                return false;
            }
            if (!this.model.username) {
                this.$store.commit("setAuthError", "Username is required");
                return false;
            }
            if (!this.model.email) {
                this.$store.commit("setAuthError", "Email is required");
                return false;
            }
            if (!this.isValidEmail(this.model.email)) {
                this.$store.commit("setAuthError", "Email is not valid");
                return false;
            }
            if (this.model.password?.length < 8) {
                this.$store.commit("setAuthError", "Passowrd must contain minimum 8 characters");
                return false;
            }

            if (!isValidPassword(this.model.password)) {
                this.$store.commit("setAuthError", "Passowrd must contain at least 1 number and 1 capital letter");
                return false;
            }
            
            if (this.model.confirmPassword !== this.model.password) {
                this.$store.commit("setAuthError", "Password and confirmation password do not match");
                return false;
            }

            // console.log('-----validate phone number', this.model.phone_number, validatePhoneNumber(this.model.phone_number));
            if (this.model.phone_number && !validatePhoneNumber(this.model.phone_number)) {
                this.$store.commit("setAuthError", "Phone number is not valid")
                return false;
            }
            if (!this.model.accept_terms) {
                this.$store.commit("setAuthError", "Please accept terms and conditions")
                return false;
            }

            // console.log('----this.recaptchaStatus', this.recaptchaStatus);
            // if (!this.recaptchaStatus) {
            //     this.$store.commit("setAuthError", "Please complete captcha");
            //     return false;
            // }

            return true;
        },
        validatePromotionInputs() {
            if (!this.model.promotion_description) {
                this.$store.commit("setAuthError", "Promotion description is required");
                return false;
            }

            return true;
        },
        validateSocialInputs() {
            const {twitter, facebook, tiktok, twitch, instagram} = this.model
            if (!twitch && !facebook && !twitter && !tiktok && !instagram) {
                this.$store.commit("setAuthError", "Please enter at least one social media account in order to continue");
                return false;
            }

            return true;
        },
        async validateReferrer() {
            this.loading = true
            const result = await this.$store.dispatch("isReferrerValid", {referrer: this.model.referral_code, username: this.model.username, email: this.model.email}) 
            // console.log('-----result', result);
            this.loading = false
            return result;
        },
        isValidEmail(email) {
            const re =
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        isReferrerExists() {
            let params = new URL(document.location).searchParams;
            // console.log("---params", params, document.location, this.$route.query.referrer);
            const referrer = this.$route.query.referrer || null;

            // console.log("--params", referrer);
            if (referrer) {
                localStorage.setItem("referrer", referrer);
                this.model.referral_code = referrer
            }
        },
        setCaptchaStatus(val) {
            this.recaptchaStatus = val
        },
        setCurrentStep(step) {
            this.currentStep = step
        },
        ensurePlusSign() {
            if (!this.model.phone_number.startsWith('+')) {
                this.model.phone_number = '+' + this.model.phone_number;
            }
        }
    },
    mounted() {
        this.i18n = this.$i18n;
        if (this.enableRTL) {
            this.i18n.locale = "ar";
            this.$rtl.enableRTL();
        }
        this.isReferrerExists();
        // const recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        //     size: 'invisible',
        //     // 'expired-callback': this.onRecaptchaExpired,
        //     // 'error-callback': this.onRecaptchaError,
        // });

        // this.recaptchaVerifier = recaptchaVerifier

    },
    beforeDestroy() {
        if (this.$rtl.isRTL) {
            this.i18n.locale = "en";
            this.$rtl.disableRTL();
        }
    },
    watch: {
        model() {
            // console.log('---model changed', this.model);
            this.ensurePlusSign()
        }
    }
};
</script>
<style>

/* .signup-parent .card {
    background-color: #C0C0C0 !important;
} */
@media(max-width: 500px) {
    .login-logo {
        width: 100px !important;
    }
}
</style>
