<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card class="login-form-card" style="margin-bottom: 20px;">

    <svg @click="$router.push('/login')" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle back-icon" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
    </svg>
    <h2 slot="header" class="title text-center">REGISTER</h2>
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1">
        <base-input
          class="text-lg"
          label="Full Name"
          placeholder="Full Name"
          v-model="model.fullname"
        >
        </base-input>
        <base-input
          class="text-lg"
          label="Username"
          placeholder="Username"
          v-model="model.username"
        >
        </base-input>
        <base-input
          class="text-lg"
          label="Email"
          placeholder="Email"
          v-model="model.email"
        >
        </base-input>
        <base-input
          class="text-lg"
          type="password"
          label="Password"
          placeholder="Password"
          v-model="model.password"
        >
        </base-input>

        <base-input
          class="text-lg"
          type="password"
          label="Confirm Password"
          placeholder="Password"
          v-model="model.confirmPassword"
        >
        </base-input>

        <base-input
          class="text-lg"
          label="Phone Number"
          placeholder="14844731705"
          v-model="model.phone_number"
          :helperText="'Allowed format: +14844731705'"
        >
        </base-input>

        <base-input
          class="text-lg"
          label="Referral Code"
          placeholder="referral code"
          v-model="model.referral_code"
          :disabled="referralCodeStatus"
        >
        </base-input>

        <base-text-area
          class="text-lg"
          label="How did you hear about us?"
          v-model="model.hear_about_us"
        >
        </base-text-area>

        <base-checkbox
          class="text-lg mb-2"
          :view="'terms'"
          v-model="model.accept_terms"
        >
        </base-checkbox>
        

        <!-- <div class="mt-3 row justify-content-center">
          <captcha 
          :setCaptchaStatus="setCaptchaStatus"
        ></captcha>
        </div> -->

        <h5 class="text-center text-danger mt-4 text-error" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
  </div>

    <div class="mt-3 column d-flex align-items-center justify-content-center">

      <div
          style="display: flex; justify-content: space-between; margin-top: -5px;"
          class="mb-1"
        >
          <small style="font-size: 0.8rem;">
            You already have an account? 
            <span @click="toLogin()" role="button" style="cursor: pointer;"><u>Login</u></span>
          </small>
          
        </div>
      <base-button  @click="register(1)" class="text-center" slot="footer" type="primary" fill>
        <clip-loader v-if="loading" color="white" size="20px" :loading="loading" ></clip-loader>
        <span style="font-size: 17px;" v-else>Next</span>
    </base-button>

    </div>
  </card>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import BaseCheckbox from '../../components/BaseCheckbox.vue';
import BaseTextArea from '../../components/Inputs/BaseTextArea.vue';
import Captcha from '../Register/Captcha.vue'

export default {
  components: {
    ClipLoader,
    BaseCheckbox,
    BaseTextArea,
    Captcha,
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    register: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    errorMessage: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    setCaptchaStatus: Function
  },
  computed: {
    referralCodeStatus() {
      return localStorage.getItem("referrer")
    }
  },
  methods: {
    toLogin() {
      this.$store.commit("setAuthError", null);
      this.$router.replace("/login")
    },
  }
};
</script>
<style></style>
