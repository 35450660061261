<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card class="login-form-card" style="margin-bottom: 20px;">

    <svg @click="goBack" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle back-icon" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
    </svg>
    <h2 slot="header" class="title text-center">REGISTER</h2>
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1">
        <h5>Please enter the verification code that you have recieved by SMS</h5>
        <base-input
          class="text-lg"
          label="Verification Code"
          placeholder="XXXXXX"
          v-model="model.verificationCode"
        >
        </base-input>
        <h5 class="text-center text-danger mt-4 text-error" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
  </div>

    <div class="mt-3 column d-flex align-items-center justify-content-center">
      <base-button  @click="verify" class="text-center" slot="footer" type="primary" fill>
        <clip-loader v-if="loading" color="white" size="20px" :loading="loading" ></clip-loader>
        <span style="font-size: 17px;" v-else>Verify</span>
    </base-button>

    </div>
  </card>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import BaseCheckbox from '../../components/BaseCheckbox.vue';
import BaseTextArea from '../../components/Inputs/BaseTextArea.vue';
import Captcha from '../Register/Captcha.vue'

export default {
  components: {
    ClipLoader,
    BaseCheckbox,
    BaseTextArea,
    Captcha,
  },
  props: {
    model: {
      type: Object,
    },
    verify: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    errorMessage: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    goBack: Function
  },
  methods: {

  }
};
</script>
<style></style>
