<!-- eslint-disable vue/no-mutating-props -->
<template>
  <card class="login-form-card" style="margin-bottom: 20px;">

    <svg @click="selectedPlatform ? selectedPlatform = null : goBack(1)" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle back-icon" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
    </svg>
    <h2 slot="header" class="title text-center">REGISTER</h2>
    <div class="d-flex align-items-center justify-content-center">
      <div class="col-md-10 px-md-1">
        <h5>Please add at least one social media account in order to complete the registration process. After you are approved you will be able to add more accounts later.</h5>
        <div class="row" v-if="!selectedPlatform">
          <div class="col-md-6" v-for="platform in platforms">
            <social-card :item="platform" :selectPlatform="selectPlatform"></social-card>
          </div>
        </div>

        <div v-if="selectedPlatform">

          <base-input
          class="text-lg"
          :label="`${selectedPlatform.title} Account`"
          :placeholder="selectedPlatform.placeholder"
          v-model="model[selectedPlatform.title?.toLowerCase()]"
        >
        </base-input>

        
        </div>

        <!-- <captcha 
          :setRecaptchaVerifier="setRecaptchaVerifier"
        ></captcha> -->

        <h5 class="text-center text-danger mt-4 text-error" v-if="errorMessage">{{  errorMessage }}</h5>
      </div>
  </div>

    <div class="mt-3 column d-flex align-items-center justify-content-center">

      <div
          style="display: flex; justify-content: space-between; margin-top: -5px;"
          class="mb-1"
        >
          <small style="font-size: 0.8rem;">
            You already have an account? 
            <span @click="toLogin()" role="button" style="cursor: pointer;"><u>Login</u></span>
          </small>
          
        </div>
      <base-button  @click="register" class="text-center" slot="footer" type="primary" fill>
        <clip-loader v-if="loading" color="white" size="20px" :loading="loading" ></clip-loader>
        <span style="font-size: 17px;" v-else>Register</span>
    </base-button>

    </div>
  </card>
</template>
<script>

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
// import PhoneVerification from './PhoneVerification.vue';
import BaseCheckbox from '../../components/BaseCheckbox.vue';
import BaseTextArea from '../../components/Inputs/BaseTextArea.vue';
import Captcha from '../Register/Captcha.vue'
import SocialCard from './SocialCard.vue'

export default {
  components: {
    ClipLoader,
    // PhoneVerification,
    BaseCheckbox,
    BaseTextArea,
    Captcha,
    SocialCard
  },
  data() {
    return {
      platforms: [
        {title: "Twitter", placeholder: "https://twitter.com/...", icon: "fab fa-twitter"},
        {title: "Facebook", placeholder: "https://facebook.com/...", icon: "fab fa-facebook"},
        {title: "Instagram", placeholder: "https://instagram.com/...", icon: "fab fa-instagram"},
        {title: "Twitch", placeholder: "https://twitch.com/...", icon: "fab fa-twitch"},
        {title: "Tiktok", placeholder: "https://tiktok.com/...", icon: "fab fa-tiktok"},
        {title: "Youtube", placeholder: "https://youtube.com/...", icon: "fab fa-youtube"},
        {title: "Snapchat", placeholder: "https://snapchat.com/...", icon: "fab fa-snapchat"},
        {title: "Discord", placeholder: "https://discord.com/...", icon: "fab fa-discord"},
      ],
      selectedPlatform: null
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    },
    register: {
      type: Function,
      default: () => {
        return () => {}
      }
    },
    errorMessage: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    setRecaptchaVerifier: Function,
    goBack: Function
  },
  methods: {
    toLogin() {
      this.$store.commit("setAuthError", null);
      this.$router.replace("/login")
    },
    selectPlatform(item) {
      this.selectedPlatform = item
    }
  }
};
</script>
<style></style>
